<template>
	<section class="domain_content">
		<!-- About Us -->
		<div class="sub-page">
			<h1>About Us</h1>
			<p>
				Thanks for visiting our site!
			</p>
			<p>
Our goal is to keep you updated with the latest update of GB WhatsApp app. The newly added features will be added to our website content. We hope that our efforts will be helpful to you, even in a small way.
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'About Us'
const description = 'Our site provides secure apk source for GB WhatsApp. Enter this site to learn more about us.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://waplusapk.download/about-us/"
            }]
	},
}
</script>
  